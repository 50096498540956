<template>
  <div class="theme-market-container">
    <div class="tabs-wrapper">
      <div class="container tabs">
        <div v-for="tab in tabs" :key="`tab-${tab.id}`"
             class="tab unselect" :class="{'tab-selected': tab.id === currentTab}"
             @click="clickTab(tab)">{{ tab.name }}</div>
      </div>
    </div>
    <themes v-if="currentTab === 1"></themes>
    <skins v-else-if="currentTab === 2"></skins>
    <templates v-else-if="currentTab === 3"></templates>
    <inquiry v-else-if="currentTab === 4"></inquiry>
  </div>
</template>

<script>
  import Themes from "./Market/Pc/Themes";
  import Skins from "./Market/Pc/Skins";
  import Templates from "./Market/Pc/Templates";
  import Inquiry from "./Market/Pc/Inquiry";
  export default {
    name: "ThemeMarketPc",
    components: {Inquiry, Templates, Skins, Themes},
    created() {
      if(this.$route.query.tab) {
        this.currentTab = Number(this.$route.query.tab);
      }

    },
    data() {
      return {
        currentTab: 1,
        tabs: [
          { id: 1, name: '플랫폼파이 테마' },
          { id: 2, name: '플랫폼파이 스킨' },
          { id: 3, name: '웹/앱 템플릿' },
          { id: 4, name: '템플릿 등록문의' }
        ]
      }
    },
    watch: {
      '$route'() {
        if(this.$route.query.tab) {
          this.currentTab = Number(this.$route.query.tab);
        }
      }
    },
    methods: {
      clickTab(item) {
        this.currentTab = item.id;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .theme-market-container
    margin 64px 0 80px 0

  .tabs-wrapper
    border-bottom 1px solid $gray2
    background-color white
  .tabs
    display grid
    grid-template-columns repeat(4, 1fr)
  .tab
    padding 16px
    text-align center
    border-bottom 2px solid transparent
    color $sub2
  .tab-selected
    color $main
    border-bottom 2px solid $main
</style>
